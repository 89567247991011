import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Podcast App Template | Podcast App Design | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/podcast-mobile-app/"
    metaDescription="Discover Podcaster, our mobile podcast app design template. Design your own podcast app, apply your own branding, and share today!"
    description="
    h2:Podcaster: Tune into our podcast mobile app design template
    <br/>
    Introducing Podcaster, Uizard's mobile podcast app design template. Packed with all the must-have features for any reputable podcasting app, Podcaster boasts a stunning simplicity alongside a natural and streamlined user experience. Adapt and create your own podcast app design for mobile.
    <br/>
    h3:Turn the volume up on your design process
    <br/>
    Tired of the lengthy design processes needed to get your app design on a screen in front of you? Uizard <a:https://uizard.io/templates/>UI templates</a> dial up the design process to eleven, meaning you can spend more time refining your design and less time building the basics from scratch. Podcaster comes pre-built with all the screens and elements you could possibly need.
    <br/>
    h3:Bring your podcast app design to life
    <br/>
    Bring your podcast app design to life, collaborate with your team, and share with team members and others. Using our Podcaster template means that you can have your own Podcast app for mobile designed and advanced to functioning <a:https://uizard.io/prototyping/>UX/UI prototype</a> in no time.
    "
    pages={[
      "A refined and elegant app landing page with clear and welcoming calls to action",
      "An interests selection page mockup, naturally linked to the main app interface",
      "A sleek footer navigation with links to all the core app pages your podcast app design could possibly need",
      "Continue listening CTAs linked intuitively to a channel feed, with further links to channel pages and an episode descriptions page",
      "Download and notification pages to demonstrate core user-first functionality of your app design ",
    ]}
    projectCode="GoeOPG4ojWcLGAyxaqg6"
    img1={data.image1.childImageSharp}
    img1alt="podcast app design template overview"
    img2={data.image2.childImageSharp}
    img2alt="podcast app design template explore screen"
    img3={data.image3.childImageSharp}
    img3alt="podcast app design template episode screen"
    img4={data.image4.childImageSharp}
    img4alt="podcast app design template discover screen"
    img5={data.image5.childImageSharp}
    img5alt="podcast app design template summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: { eq: "templates/podcast-mobile-app/podcast-app-cover.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/podcast-mobile-app/podcast-app-explore.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/podcast-mobile-app/podcast-app-episode.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/podcast-mobile-app/podcast-app-discover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/podcast-mobile-app/podcast-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
